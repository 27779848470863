const routes = [
    {
        path: "/raise/rates",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true ,
            permission:'raiserate_index'

        }
    }
];

export default routes;

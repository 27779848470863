const translations = {

    document_request_info_text: "In order to facilitate access to documents requested by our students (student certificates, transcripts, discipline reports, language proficiency certificates, etc.) requests for student documents with qualified electronic signature are to be made through OIS. However, students who request documents with electronic signature must first apply for the relevant documents through OIS and then visit the Student Affairs Office at Beşiktaş Campus to pick them up.We would like to remind you that original signed documents and documents with qualified electronic signature are “official documents” and therefore; using them in violation with the current laws and regulations or outside their intended purposes, modifying them or allowing other persons to use them constitute acts that require disciplinary punishments within the scope of the Higher Education Institutions Student Discipline Regulation and are considered as crimes subject to criminal sanctions within the scope of the Turkish Criminal Law.You may get your Student Certificate through the E-Government portal using your E-Government password. Student certificates obtained through E-Government are valid in all relevant institutions and organizations.",
    course_transfer_form_info: "You may click on \"Send Request\" button to create your request after you upload your course transfer document(s). You will be notified when your course transfer process is completed. You are required to confirm your course transfer process by clicking on \"Course Transfer\" on \"My Requests\" page. For those who with transcripts from Bahçeşehir University, the transcripts will be uploaded automatically by the system. Please refer to the <a href=\"#\" class='text-primary' download>User Guide</a> for further details.",
    privacy_notice_approve: `I have read, understood, and accept the <strong>clarification text on the protection of personal data</strong>`,
    privacy_notice_message: `BAHÇEŞEHİR UNIVERSITY, located at Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul (hereinafter referred to as "FUTURE CAMPUS"), provides the basic information regarding the protection of personal data transferred to or obtained by our organization below. <br>
        The detailed explanations below are presented to the attention of individuals using our website and/or future mobile applications, as well as participants in our events, business partners, employees, consultants, customers, and individuals visiting our workplaces or connecting to our internet network, in accordance with Article 10 of the Personal Data Protection Law No. 6698 (“KVKK”).<br>
        FUTURE CAMPUS reserves the right to update this Privacy Notice, which has been prepared in compliance with the applicable legislation, in line with any changes in the applicable legal framework. In such cases, updates and changes to the Privacy Notice will be explicitly stated on our websites and mobile applications.<br>
        Personal data shared with FUTURE CAMPUS is under the supervision and control of FUTURE CAMPUS. FUTURE CAMPUS assumes the responsibility as the data controller to establish, take, and adapt the necessary technical measures and organization to protect the confidentiality and integrity of information in accordance with applicable legislation. We assure you that periodic penetration tests are conducted in line with international and national technical standards related to data privacy and that our data processing policies are continuously updated.<br>
        <strong>1. LEGAL BASIS FOR PERSONAL DATA COLLECTION</strong><br>
        As FUTURE CAMPUS, we conduct activities in compliance with the applicable legislation regarding personal data processing. This includes primarily the KVKK, which defines general principles regarding personal data protection, and relevant provisions of the Turkish Penal Code No. 5237 that impose criminal sanctions in certain cases.<br>
        <strong>2. METHODS OF PERSONAL DATA COLLECTION</strong><br>
        Personal data you provide while using our website at <a href="www.futureaisummit.org">www.futureaisummit.org</a> (“Website”) and/or our future mobile applications (“Mobile Application”), participating in events such as the Future AI Summit or other activities, contacting us through any channel, attending our events, meetings, panels, or seminars, visiting our organization premises, making payments for event participation or support/sponsorship, applying for membership to our Platforms, or simply visiting our Platforms (collectively referred to as “Platform”), are processed by the relevant departments of FUTURE CAMPUS in compliance with this Privacy Notice and the Privacy Policy on our Website, in accordance with your consent and applicable legal provisions.<br>
        <strong>3. AREAS OF PERSONAL DATA USAGE</strong><br>
        Personal data obtained by FUTURE CAMPUS in compliance with applicable legislation is shared with third parties only within the framework of legal obligations, the consent of the data subject, or exceptions stipulated by the applicable legislation. Personal data is not shared with third parties in violation of the applicable legislation, except in cases required for the performance of services or transactions.<br>
        In addition, personal data may be shared with courts and other public authorities to fulfill legal obligations. Data transfer to third parties is conducted under technical and legal safeguards to prevent rights violations. However, FUTURE CAMPUS is not responsible for violations arising from the data protection policies of third parties or risks within their responsibility.<br>
        Personal data may be shared with the management of FUTURE CAMPUS, suppliers, business partners, supporters, and sponsors; institutions and organizations that support projects; cloud storage providers (domestic/foreign); Platform stakeholders; institutions providing commercial electronic communication services; banks and payment systems; various agencies and survey companies; and other domestic and international third parties, for purposes specified above.<br>
        <strong>4. PURPOSES OF PERSONAL DATA PROCESSING</strong><br>
        Your personal data collected by FUTURE CAMPUS may be processed for the following purposes:<br>
        · Verifying and confirming the identity of individuals registering on the Platforms controlled by FUTURE CAMPUS;<br>
        · Recording contact and other necessary information of individuals collaborating with FUTURE CAMPUS in various capacities;<br>
        · Communicating updates or information about agreements, partnerships, or collaborations with Platform users;<br>
        · Organizing records and documents necessary for any transactions conducted electronically or in paper format;<br>
        · Meeting public safety-related requests from public authorities as required by applicable laws;<br>
        · Improving user experience and satisfaction for Platform users and visitors;<br>
        · Conducting analyses and surveys for better service and feedback collection.<br>
        <strong>5. RIGHTS OF PERSONAL DATA OWNERS</strong><br>
        In accordance with Article 11 of the KVKK, you have the following rights:<br>
        a) To learn whether your personal data is processed; if so, request information;<br>
        b) To learn the purpose of processing and whether it is used in accordance with that purpose;<br>
        c) To know the third parties to whom personal data is transferred domestically or internationally;<br>
        d) To request correction of incomplete or incorrect data;<br>
        e) To request deletion or destruction of personal data as stipulated in Article 7 of the KVKK;<br>
        f) To request notification of these actions to third parties to whom personal data has been transferred;<br>
        g) To object to results against you derived from analysis conducted solely by automated systems;<br>
        h) To request compensation for damages if personal data is processed unlawfully.<br>
        <strong>6. CONSENT</strong><br>
        By contacting FUTURE CAMPUS or sharing your personal data in any manner, you consent to the collection, processing, and sharing of your personal data by FUTURE CAMPUS as outlined above, and in accordance with our Privacy Policy and the KVKK.<br>
        <strong>7. APPLICATION</strong><br>
        Personal data owners can send questions, opinions, or requests via email to <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a> or through physical or postal delivery to Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul. Requests must be submitted using the Application Form available at <a href="www.futureaisummit.org">www.futureaisummit.org</a>.<br>
        <strong>This notice has been prepared in compliance with Article 10 of the KVKK to fulfill the obligation of disclosure.</strong><br>
        <strong>BAHÇEŞEHİR UNIVERSITY</strong><br>
        <strong>Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul</strong><br>
        <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a>`,
    privacy_policy_approve:`I have read, understood, and accept the <strong>privacy agreement</strong> regarding the protection of personal data.`,
    privacy_policy_message: `
        <strong>1. INTRODUCTION</strong><br>
        As BAHÇEŞEHİR UNIVERSITY located at Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul (hereinafter referred to as “FUTURE CAMPUS”), we attach the utmost importance to the security of your personal data. Your personal data is processed and stored by us in accordance with the Personal Data Protection Law No. 6698 (“KVKK”).<br>
        By using or visiting the website at <a href="www.futureaisummit.org">www.futureaisummit.org</a> (“Website”), future mobile applications (“Mobile Application”), or any online platform or physical location controlled by FUTURE CAMPUS (collectively referred to as the “Platform”), and by participating in the organized events (“Events”), you confirm that you have read, understood, and accepted this Privacy Policy (“Policy”).<br>
        Any changes made to the provisions of this Policy will be updated and published on our Website, with the effective date of the changes explicitly stated. For 30 (thirty) days following the changes, we will notify you of the changes through a reasonable and noticeable link on the Website.<br>
        <strong>2. INFORMATION AS THE DATA CONTROLLER</strong><br>
        As FUTURE CAMPUS, in accordance with the Personal Data Protection Law No. 6698 (“KVKK”) and the provisions of the applicable legislation, your personal data will be recorded, stored, updated, disclosed to third parties where permitted by legislation, transferred, classified, and processed in the ways specified in this Policy as the data controller.<br>
        <strong>3. HOW PERSONAL DATA IS PROCESSED</strong><br>
        Your personal data shared with FUTURE CAMPUS may be fully or partially processed by automatic or non-automatic means, as part of a data recording system, by collecting, recording, storing, modifying, or reorganizing data. Under KVKK, all such operations are considered “processing of personal data.”<br>
        <strong>4. TYPES OF PERSONAL DATA THAT MAY BE PROCESSED</strong><br>
        FUTURE CAMPUS collects certain personal data that you provide for purposes such as registering on any Platform owned by FUTURE CAMPUS, performing transactions, providing monetary or in-kind support through these Platforms, benefiting from such support, participating in Events, obtaining information, making purchases, subscribing to newsletters, or establishing a connection with FUTURE CAMPUS through third-party platforms. Additionally, certain data such as device information and interactions on the Platform may be collected automatically.<br>
        Below are some examples of the data we collect to provide a better understanding:<br>
        <strong>Account Data:</strong> When you register or update your account to use specific features (such as creating a user account, registering for events, or using services on the Platforms), we collect and store data such as your name, surname, email address, phone number, date of birth/age, gender, address, country of residence, company name and sector, and job title.<br>
        <strong>Shared Content:</strong> You may interact and share public content such as comments, questions, or answers on FUTURE CAMPUS-related social media or websites. Depending on where the shared content is published, it may be publicly viewable by others.<br>
        <strong>User and Visitor Data:</strong> When you register for or attend Events, we collect specific data regarding the services you use or other elements you submit to meet FUTURE CAMPUS service requirements.<br>
        <strong>Payment Data:</strong> We collect data related to payments you make within the Platforms (e.g., name, surname, information about the payment service, and address). Sensitive cardholder data such as credit card numbers or verification codes are not collected or stored by FUTURE CAMPUS for security reasons.<br>
        <strong>Partner Data:</strong> If you are an employee, consultant, partner, supporter, or volunteer of FUTURE CAMPUS, we collect your personal data related to payment accounts and manage it under this Policy.<br>
        <strong>Data About Other Accounts:</strong> If your account on FUTURE CAMPUS Platforms is linked to another account (e.g., social media or online accounts), we may obtain certain information from such accounts. Depending on the platform or service, we may access data such as your name, profile picture, account ID, email address, location, physical location of access devices, gender, birth date, and friends or contact lists.<br>
        <strong>Surveys, Promotions, and Contests:</strong> If you participate in surveys, promotions, or contests through the Platforms, we collect data such as your name, surname, email address, phone number, and other related information.<br>
        <strong>Communication and Support:</strong> When you contact us for support or to report a concern, we collect and store data such as your name, email, location, operating system, IP address, and other data provided by you or collected automatically.<br>
        <strong>5. PURPOSES OF PERSONAL DATA PROCESSING</strong><br>
        Your personal data is processed for the following purposes:<br>
        · To provide and facilitate the services offered to you and the activities carried out with our partners;<br>
        · To communicate with you and manage promotional and awareness activities;<br>
        · To provide information to prosecutors, courts, and public officials upon request and as required by law in matters of public safety and legal disputes;<br>
        · To offer various opportunities to you or share these opportunities with relevant individuals or institutions within the legal framework.<br>
        <strong>6. METHODS OF PERSONAL DATA COLLECTION</strong><br>
        Your personal data may be collected:<br>
        · Via forms on the Website and Mobile Applications or other Platforms;<br>
        · Through activities and consultations via digital channels, physical forms, or other communication methods;<br>
        · Through indirect means such as public databases, social media, newsletters, and other sources.<br>
        <strong>7. TRANSFER OF PERSONAL DATA</strong><br>
        FUTURE CAMPUS may transfer your personal data for purposes outlined in this Policy and in accordance with KVKK Articles 8 and 9 to collaborators, regulatory authorities, and authorized institutions.<br>
        <strong>8. STORAGE AND PROTECTION OF PERSONAL DATA</strong><br>
        FUTURE CAMPUS takes appropriate measures to protect personal data from loss, misuse, or alteration.<br>
        <strong>9. ACCURACY OF PERSONAL DATA</strong><br>
        Under KVKK Article 4, you are responsible for keeping your personal data accurate and updated.<br>
        <strong>10. RIGHTS OF PERSONAL DATA OWNERS</strong><br>
        As per KVKK Article 11, you have the following rights:<br>
        a) To learn whether your personal data has been processed;<br>
        b) To request information about processing;<br>
        c) To learn the purpose of processing;<br>
        d) To request corrections if data is incomplete or inaccurate;<br>
        e) To request deletion or destruction of your data;<br>
        f) To object to the processing of data;<br>
        g) To request compensation for damages caused by unlawful processing.<br>
        <strong>11. APPLICATION</strong><br>
        Personal data owners can submit their inquiries or requests to <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a> or via mail to Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul. Requests must be submitted using the Application Form available on <a href="www.futureaisummit.org">www.futureaisummit.org</a>.<br>
        <strong>BAHÇEŞEHİR UNIVERSITY</strong><br>
        <strong>Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul</strong><br>
        <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a>`,
    distance_contract_approve:`I have read, understood, and accepted the <strong>distance sales agreement</strong> and the protection of personal data.`,
    distance_contract_message: `<strong>1. PARTIES</strong><br>
    Distance Sales Agreement (hereinafter referred to as the "Agreement"):<br>
    <strong>1.1</strong> On one side, <strong><i>BAHÇEŞEHİR UNIVERSITY</i></strong>, located at <i><strong>Çırağan Cad. Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/İstanbul</strong></i> (hereinafter referred to as the <strong><i>"Seller"</i></strong>);<br>
    <strong>1.2</strong> On the other side, the person named ……………………………………….., residing at ………………………………….. (hereinafter referred to as the <strong>“Buyer”</strong>), has entered into this agreement.<br>
    The Seller and the Buyer shall be referred to separately as <strong>“Party”</strong> and collectively as <strong>“Parties”</strong>.<br>
    By accepting this Agreement, the Buyer acknowledges in advance that upon confirming the order subject to the Agreement, they will be liable for the payment of the order amount and any additional charges such as shipping fees and taxes, if applicable, and that they have been explicitly informed about this matter.<br><br>
    <strong>2. PURPOSE AND SUBJECT</strong><br>
    The subject of this Agreement is to determine the rights and obligations of the Parties in accordance with the provisions of the Consumer Protection Law No. 6502 and the Regulation on Distance Contracts regarding the certificate purchased by the Buyer for the FUTURE AI SUMMIT event through the Seller's website <a href="https://www.futureaisummit.org">www.futureaisummit.org</a> <strong>("Website")</strong>.<br><br>
    <strong>3. PRODUCTS AND SERVICES SUBJECT TO THE AGREEMENT</strong><br>
    The Buyer purchases the certificate for the FUTURE AI SUMMIT event electronically through the Website in exchange for the specified price.<br>
    The fundamental characteristics of the event and the certificate (type, quantity, quality, scope) are published on the Website. If the Seller organizes a campaign, you can review the fundamental characteristics of the relevant service during the campaign period. The campaign is valid within the specified date range.<br>
    The listed and published prices on the Website are the sales prices. The announced prices and offers remain valid until they are updated and/or changed. Prices announced for a limited time are valid until the specified period ends.<br><br>
    <strong>4. PAYMENT METHOD, TICKET PRICE, AND PAYMENT TERMS</strong><br>
    <strong>4.1</strong> The certificate fee is the price specified on the Website at the time of purchase, and the total amount, including VAT, is displayed to the Buyer before the order is completed.<br>
    <strong>4.2</strong> Payment can be made via credit card, debit card, bank transfer, or other specified payment methods.<br>
    <strong>4.3</strong> If, for any reason, the Buyer fails to pay the certificate fee or if the payment is canceled in bank records, the Seller shall be deemed to be released from the obligation to deliver the certificate.<br>
    <strong>4.4</strong> In cases where the organization of the event becomes impossible, the Seller shall inform the Buyer within 3 (three) days from the date of learning of this situation and shall refund all payments collected, including any delivery costs if applicable, to the Buyer within a maximum of 14 (fourteen) days from the notification date.<br><br>
    <strong>5. DELIVERY AND USAGE TERMS</strong><br>
    <strong>5.1</strong> The Buyer will receive the purchased certificate digitally at their registered email address after the purchase is completed.<br>
    <strong>5.2</strong> The Buyer may use the purchased certificate only on their behalf or on behalf of an authorized person. The transfer or sale of the participation right related to the certificate is not permitted without the Seller’s written approval.<br><br>
    <strong>6. RIGHTS AND OBLIGATIONS OF THE PARTIES</strong><br>
    <strong>6.1</strong> By becoming a member of the Seller’s Website and/or completing the certificate purchase process, you are deemed to have read, understood, and fully accepted this Agreement, the Terms of Use, the Privacy Policy, and the Cookie Policy. In case of any claim or complaint contrary to this Agreement or its provisions, it is recommended to first contact the Seller using the communication channels specified on the Website before purchasing any certificate. By purchasing any certificate from the Website, you agree to be bound by the terms of this Agreement.<br>
    <strong>6.2</strong> The Buyer acknowledges that they have read and are informed about the fundamental characteristics of the certificate for the event on the Website, its sales price, payment method, and the preliminary information regarding its execution, and that they have provided the necessary confirmation electronically. The Buyer declares, acknowledges, and undertakes that by confirming the preliminary information electronically before the establishment of the distance sales agreement, they have accurately and completely obtained the fundamental characteristics of the event and certificates, the price of the certificate including taxes, and the payment and delivery details provided by the Seller.<br>
    <strong>6.3</strong> The Buyer will be able to select a certificate through the Website, provided that they supply the information requested by the Seller.<br>
    <strong>6.4</strong> The Seller has the right to offer a different certificate of similar quality and price, provided that they inform the Buyer and obtain their explicit approval before the fulfillment period of the contractual obligation expires.<br>
    <strong>6.5</strong> If the fulfillment of the service related to the event for which the certificate is issued becomes impossible and the Seller is unable to fulfill its contractual obligations, the Seller shall notify the Buyer in writing within 3 (three) business days from the date of learning of this situation. In such a case, if the Buyer does not request to exchange their participation right for a similar one, the Seller shall refund the total amount to the Buyer within 14 (fourteen) business days.<br>
    <strong>6.6</strong> If the participation right for the event related to the certificate is purchased using the Buyer's credit card due to unauthorized use by third parties, and the payment for the certificate is not made to the Seller by the relevant bank or financial institution, the Buyer agrees to notify the Seller within 3 (three) days and cancel the purchase record related to the agreement.<br>
    <strong>6.7</strong> If the Seller is unable to supply the certificate subject to the contract or fulfill its commitment to provide the participation right due to force majeure events that arise beyond the Parties' control, are unforeseen, and prevent and/or delay the fulfillment of their obligations, the Seller shall inform the Buyer. In such cases, the Buyer has the right to request the cancellation of the contract, the replacement of the certificate with a similar one if available, and/or the postponement of the services related to the certificates until the obstructing circumstances are resolved. If the Buyer cancels the purchase of the certificates, any payments made in cash will be refunded to the Buyer in full within 14 (fourteen) days. For payments made by credit card, the certificate amount will be refunded to the relevant bank within 14 (fourteen) days after the Buyer cancels the order. The Buyer acknowledges that the process of the refunded amount being reflected in their bank account may take an average of 2-3 weeks, depending on the bank's internal processes, and that the Seller cannot be held responsible for any possible delays caused by the bank’s processing time.<br>
    <strong>6.8</strong> If the Buyer and the credit card holder used during the purchase are not the same person, or if a security issue is detected regarding the credit card before the event takes place or before delivery, the Seller may request the Buyer to provide identification and contact details of the credit card holder, a copy of the previous month's statement of the credit card used during registration, or a letter from the cardholder’s bank confirming that the credit card belongs to them. The purchase process will be suspended until the Buyer provides the requested information and documents. If the Buyer fails to fulfill the Seller's requests within 24 hours, the Seller reserves the right to cancel the purchase transaction.<br>
    <strong>6.9</strong> The Buyer who purchases the certificate right from the Seller may not use this right for any commercial business or activity without the Seller’s written permission. The Buyer may not claim any copyrights or attempt to sell any materials related to the certificate or the event for an additional fee or by any other means. In case of violation, the Seller reserves the right to take legal action.<br>
    <strong>6.10</strong> Based on prior approvals obtained from the Buyer, the Seller may send commercial electronic messages under this Agreement. Since this approval can be obtained in writing or through any electronic communication means, if the Buyer does not wish to receive commercial electronic messages under the Agreement, they must uncheck the <i>“electronic message consent”</i> section. The Buyer has the right to refuse to receive commercial electronic messages at any time, without providing any reason. However, if the Buyer provides their contact information to the Seller for communication purposes, the Seller states that no additional approval will be required for commercial electronic messages related to changes and/or usage of the services provided.<br>
    <strong>6.11</strong> If the Seller has obtained the Buyer’s contact information during the supply of products or the provision of any other services, while informing the Buyer that such communication may take place and providing an option to opt out, it is legally permissible to communicate with the Buyer regarding the marketing, promotion, and/or modification of the same or similar services without requiring prior consent.<br>
    <strong>6.12</strong> At the stage of confirming the purchase transaction, before entering payment details, the Buyer will be able to clearly see the amounts they will be required to pay when purchasing the certificate. Therefore, the amounts to be collected by the Seller as a result of the purchase transaction, which will be carried out at the Buyer’s discretion, shall be deemed to have been approved by the Buyer.<br>
    <strong>6.13</strong> The Seller shall be responsible for the storage and security of the personal data obtained regarding the Buyer. The Buyer’s personal data may be processed by the Seller within the scope of the legal relationship conducted with the Buyer in relation to their activities, in accordance with the Seller’s Privacy Policy, and may be shared with third parties.<br>
    <strong>6.14</strong> The privacy rules regarding the use of the Website and this Agreement are detailed in the <i>“Privacy Policy”</i> and <i>“Terms of Use”</i> sections. In this context, it is the Buyer’s responsibility to carefully read and review the Privacy Policy and Terms of Use and to notify the Seller in case of any different requests. The Privacy Policy is an annex and an integral part of this Agreement and shall be applied and interpreted together with the Agreement. Therefore, the approval of this Agreement and/or the order shall also mean acceptance of the Privacy Policy provisions.<br><br>
    <strong>7. RIGHT OF WITHDRAWAL AND CANCELLATION</strong><br>
    <strong>7.1</strong> The Buyer may not exercise the right of withdrawal due to the nature of event services, in accordance with the relevant provisions of the Consumer Protection Law No. 6502.<br>
    <strong>7.2</strong> If the event is canceled due to force majeure circumstances, the Seller shall fully refund the certificate fee to the Buyer.<br><br>
    <strong>8. DEFAULT AND LEGAL CONSEQUENCES</strong><br>
    The Buyer acknowledges, declares, and undertakes that if they fall into default when making payment transactions via credit card, they will be liable for paying interest and will be responsible to the bank under the credit card agreement between them and the issuing bank. In such cases, the relevant bank may take legal action, demand any arising costs and attorney fees from the Buyer, and, in any case, if the Buyer defaults on their debt, they agree, declare, and undertake to compensate the Seller for any damages and losses incurred due to the delayed fulfillment of the debt.<br><br>
    <strong>9. CONFIDENTIALITY AND PROTECTION OF PERSONAL DATA</strong><br>
    The Seller shall process the Buyer’s personal data in accordance with the provisions of the Personal Data Protection Law No. 6698 (KVKK) and the Privacy Policy published on the Website, which has also been accepted during the purchase process. The Buyer consents to the processing of their personal data for event participation and billing purposes.<br><br>
    <strong>10. NOTIFICATIONS</strong><br>
    If the Buyer falls into default when making payment transactions via credit card, they acknowledge, declare, and undertake that they will be liable for paying interest and will be responsible to the bank under the credit card agreement between them and the issuing bank. In such cases, the relevant bank may take legal action, demand any arising costs and attorney fees from the Buyer, and in any case, if the Buyer defaults on their debt, they agree, declare, and undertake to compensate the Seller for any damages and losses incurred due to the delayed fulfillment of the debt.<br><br>
    <strong>11. COMPETENT COURT</strong><br>
    In the event of any dispute arising from this Agreement and/or from the purchase of any product or service in any manner, it is essential to first attempt to resolve the dispute amicably. If an amicable resolution is not possible, the Buyer, in their capacity as a consumer, may bring any disputes before the Consumer Arbitration Committees and/or Consumer Courts within the monetary limits specified in the relevant legislation. For all disputes arising from this Agreement, the Istanbul (Çağlayan) Courts and Enforcement Offices shall have jurisdiction.<br><br>
    <strong>12. EVIDENCE AGREEMENT</strong><br>
    All terms and conditions set forth in this Agreement shall be governed by Turkish Law. The Seller informs all Buyers in advance about the terms and content of the Agreement before they purchase any product and/or service. By purchasing a product and/or service and confirming the order, it shall be deemed that the Buyer has been provided with prior information regarding the terms of this Agreement. This clause shall have the nature of written evidence.<br><br>
    <strong>13. ENFORCEMENT</strong><br>
    By purchasing a certificate through the Website, the Buyer declares that they accept all terms of this Agreement. This Agreement shall enter into force upon the completion of the Buyer’s certificate purchase transaction.<br><br>`,
}

export default translations



<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event, multiple)"
			label="text"
			track-by="value"
			:multiple="multiple"
			:close-on-select="!multiple"
			:clear-on-select="!multiple"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:disabled="disabled">
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
		        <span class="multiselect__single" v-if="values.length && !isOpen">
			        {{ translateNSelected(values) }}
		        </span>
			</template>
			<span slot="noOptions">{{ $t("no_options") }}</span>
			<span slot="noResult">{{ $t("no_result") }}</span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import ProgramServices from "@/services/ProgramService";
	import UndergraduateTransferPreferenceService from '@/services/UndergraduateTransferPreferenceService'

	// Other
	import qs from "qs";

	export default {
		props: {
            is_major_type:{
              type:Boolean,
              default:false
            },
            is_faculty_code_required: {
                type: Boolean,
                default: false,
            },
			faculty_code: {
				default: null,
			},
            is_department_code_required: {
                type: Boolean,
                default: false,
            },
			department_code: {
				default: null,
			},
			major_type: {
				type: String,
				default: null,
			},
			value: {
				default: null,
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			validateError: {
				type: String,
				default: '',
			},
            public: {
                type: Boolean,
                default: false
            },
            isActive: {
                type: Boolean,
                default: true,
            },
			requestType: {
				type: String,
				default: ""
			},
			name: {
				type:String,
				default:""
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple)
			},
			faculty_code: function () {
				this.getOptions()
			},
			department_code: function () {
				this.getOptions()
			},
		},
		created() {
			this.getOptions()
		},
		methods: {
			translateNSelected,
			handleInput,
			setSelected,
            getOptions(){
                if (this.is_faculty_code_required && this.faculty_code == null) {
                    return;
                }
                if (this.is_department_code_required && this.department_code == null) {
                    return;
                }

                const config = {
                    params: {
                        filter: {
                            faculty_code: this.faculty_code,
                            department_code: this.department_code,
                            major_type: this.major_type,
                            status: this.isActive ? 'a':'',
							name:this.name
                        },
                        sort: 'code',
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                this.clearOptions();
                if (this.public) {
					if(this.requestType) {
						if(this.requestType === "preference") {
							UndergraduateTransferPreferenceService.preferencePrograms(this.faculty_code)
                                   .then((response) => {
                                       this.options = [];
                                       const data = response.data.data;
                                       data.map((item) => {
                                           this.options.push({
                                               value: item.code,
                                               text: item.code + ' - ' + this.getLocaleText(item, 'name'),
                                           })
                                       })
                                   })
                                   .then(() => {
                                       this.selected = this.setSelected(this.value, this.options, this.multiple)
                                   })
						}

					}else {
						ProgramServices.getAllPublic(config)
                                   .then((response) => {
                                       this.options = [];
                                       const data = response.data.data;
                                       data.map((item) => {
                                           this.options.push({
                                               value: item.code,
                                               text: item.code + ' - ' + this.getLocaleText(item, 'name'),
                                           })
                                       })
                                   })
                                   .then(() => {
                                       this.selected = this.setSelected(this.value, this.options, this.multiple)
                                   })
					}
                }
                else {
                    ProgramServices.getAll(config)
                                   .then((response) => {
                                       this.options = [];
                                       const data = response.data.data;
                                       data.map((item) => {
                                           this.options.push({
                                               value: item.code,
                                               text: item.code + ' - ' + this.getLocaleText(item, 'name'),
                                           })
                                       })
                                   })
                                   .then(() => {
                                       this.selected = this.setSelected(this.value, this.options, this.multiple)
                                   })
                }
            },

			clearOptions() {
				this.selected = null;
				this.options = [];
			}
		}
	}
</script>

const routes = [
    {
        path:'/graduation/simulation',
        component:()=>import('../pages/index.vue'),
        meta: {
            isAuthenticated: true,
            permission: "simulation_graduationsimulation"
        }
    }
];

export default routes

const routes = [
    {
        path: "/excel/export",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "excelexport_index"
        }
    },
    {
        path: "/excel/export/fields/:id",
        component: () => import("../pages/CrudItem.vue"),
        meta: {
            isAuthenticated: true,
            permission: "excelexportfield_index"
        },
    },
];

export default routes;
